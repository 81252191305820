import React from "react";
import {
  // BrowserRouter as Router,
  HashRouter as Router,
  Switch,
  Route
} from "react-router-dom";
import Products from "./Products";
import Product from "./Product";
import Header from "./Header";
import Footer from "./Footer";
import About from "./About";
import Contact from "./Contact";
import Order from "./Order";
import Home from "./Home";

function App() {
  return (
    <Router>
      <Header />
      <Switch>
        <Route path="/order"><Order /></Route>
        <Route path="/contact"><Contact /></Route>
        <Route path="/about"><About /></Route>
        <Route path="/products/:categoryId"><Products /></Route>
        <Route path="/products"><Products /></Route>
        <Route path="/product/:productId"><Product /></Route>
        <Route path="/product"><Product /></Route>
        <Route path="/" exact><Home /></Route>
        <Route path="*"><NoMatch /></Route>
      </Switch>
      <Footer />
    </Router>
  );
}

function NoMatch() {
  return (
    <div>
      <div id="colorlib-home" style={{paddingTop: 2+"em", paddingBottom: 0}}>
        <div class="container">
          <h4>Page Not Found</h4>
        </div>
      </div>
    </div>
)}

export default App;