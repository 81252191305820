import React from "react";

function Footer() {
  return (
		<footer id="colorlib-footer" role="contentinfo" style={{paddingTop: 0+"em", paddingBottom: 0}}>

			{/* <div class="container">
				<div class="row row-pb-md">
					<div class="col footer-col colorlib-widget">
						<h4>About VEGACE</h4>
						<p>Even the all-powerful Pointing has no control about the blind texts it is an almost unorthographic life</p>
						<p>
							<ul class="colorlib-social-icons">
								<li><a href="#"><i class="icon-twitter"></i></a></li>
								<li><a href="#"><i class="icon-facebook"></i></a></li>
								<li><a href="#"><i class="icon-linkedin"></i></a></li>
								<li><a href="#"><i class="icon-dribbble"></i></a></li>
							</ul>
						</p>
					</div>
					<div class="col footer-col colorlib-widget">
						<h4>Customer Care</h4>
						<p>
							<ul class="colorlib-footer-links">
								<li><a href="#">Contact</a></li>
								<li><a href="#">Returns/Exchange</a></li>
								<li><a href="#">Gift Voucher</a></li>
								<li><a href="#">Wishlist</a></li>
								<li><a href="#">Special</a></li>
								<li><a href="#">Customer Services</a></li>
								<li><a href="#">Site maps</a></li>
							</ul>
						</p>
					</div>
					<div class="col footer-col colorlib-widget">
						<h4>Information</h4>
						<p>
							<ul class="colorlib-footer-links">
								<li><a href="#">About us</a></li>
								<li><a href="#">Delivery Information</a></li>
								<li><a href="#">Privacy Policy</a></li>
								<li><a href="#">Support</a></li>
								<li><a href="#">Order Tracking</a></li>
							</ul>
						</p>
					</div>

					<div class="col footer-col">
						<h4>News</h4>
						<ul class="colorlib-footer-links">
							<li><a href="#">Blog</a></li>
							<li><a href="#">Press</a></li>
							<li><a href="#">Exhibitions</a></li>
						</ul>
					</div>

					<div class="col footer-col">
						<h4>Contact Information</h4>
						<ul class="colorlib-footer-links">
							<li>2315 Rosemead Blvd. <br /> South El Monte, CA 91733</li>
							<li><a href="tel://6265758650">626-575-8650</a></li>
							<li><a href="mailto:info@vegace.com">info@vegace.com</a></li>
							<li><a href="https://vegace.com">vegace.com</a></li>
						</ul>
					</div>
				</div>
			</div> */}

			<div class="copy">
				<div class="row">
					<div class="col-sm-12 text-center">
						<p>
							<span>
								Copyright &copy;{new Date().getFullYear()} All rights reserved | This template is made with <i class="icon-heart" aria-hidden="true"></i> by <a href="https://colorlib.com" target="_blank" rel="noopener noreferrer">Colorlib</a>
							</span> 
							<span class="block">Demo Images: <a href="http://unsplash.co/" target="_blank" rel="noopener noreferrer">Unsplash</a> , <a href="http://pexels.com/" target="_blank" rel="noopener noreferrer">Pexels.com</a></span>
						</p>
					</div>
				</div>
			</div>
		</footer>
  )
}

export default Footer;