import React, { useState } from "react";
import {
	Link,
	useLocation,
	useHistory,
	useRouteMatch,
	withRouter
} from "react-router-dom";

function SearchForm(props) {
	let history = useHistory();
  const [searchTerm, setSearchTerm] = useState("");
  
  const handleSubmit = (e) => {
		e.preventDefault();
		setSearchTerm("");
		history.replace(`/product/${searchTerm}`);
  }
  return (
							<form onSubmit={handleSubmit} class="search-wrap">
								<div class="form-group">
									<input type="search" class="form-control search" placeholder="Style #" value={searchTerm} onChange={e => setSearchTerm(e.target.value)} />
									<button class="btn btn-primary submit-search text-center" type="submit"><i class="icon-search"></i></button>
								</div>
							</form>
	);
}
withRouter(SearchForm);

function Header() {
	let location = useLocation();
  return (
		<nav class="colorlib-nav" role="navigation">
			<div class="top-menu">
				<div class="container">
					<div class="row">
						<div class="col-sm-7 col-md-9">
							<div id="colorlib-logo"><Link to="/" exact><img src="shoes/vegace_logo.gif" alt="VEGACE" style={{ verticalAlign: "top"}} /> <img src="shoes/lavega_logo.gif" alt="VEGACE" style={{ verticalAlign: "top"}} /></Link></div>
						</div>
						<div class="col-sm-5 col-md-3">
							{/* <form name="searchForm" action="#/product?" class="search-wrap">
								<div class="form-group">
									<input name="pid" type="search" class="form-control search" placeholder="Style #" />
									<button class="btn btn-primary submit-search text-center" type="submit"><i class="icon-search"></i></button>
								</div>
							</form> */}
							<SearchForm />
						</div>
					</div>
					<div class="row">
						<div class="col-sm-12 text-left menu-1">
							<ul>
								<li class={ location.pathname === "/" ? "active" : "" }><Link to="/" exact>Home</Link></li>
								<li class={ location.pathname.startsWith("/product") ? "active" : "" }><Link to="/products">Products</Link></li>
								<li class={ useRouteMatch("/about") ? "active" : "" }><Link to="/about">About</Link></li>
								<li class={ useRouteMatch("/contact") ? "active" : "" }><Link to="/contact">Contact</Link></li>
								<li class={ useRouteMatch("/order") ? "active" : "" }><Link to="/order">Order</Link></li>
							</ul>
						</div>
					</div>
				</div>
			</div>
			<div class="sale">
				<div class="container">
					<div class="row">
						<div class="col-sm-8 offset-sm-2 text-center">
							<div class="row">
								<div class="owl-carousel2">
									<div class="item">
										<div class="col">
											<h3><Link to="/about">Welcome to VEGACE CORP</Link></h3>
										</div>
									</div>
									<div class="item">
										<div class="col">
											<h3><Link to="/products">Shop VEGACE and LAVEGA Shoes</Link></h3>
										</div>
									</div>
									<div class="item">
										<div class="col">
											<h3><Link to="/order">Download Order Form</Link></h3>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</nav>
  )
}

export default Header;