import React from "react";
import {
  Link
} from "react-router-dom";

function Contact() {
  return (
    <div>
      <div class="breadcrumbs">
        <div class="container">
          <div class="row">
            <div class="col">
              <p class="bread"><span><Link to="/">Home</Link></span> / <span>Contact</span></p>
            </div>
          </div>
        </div>
      </div>

      <div id="colorlib-contact" style={{paddingTop: 1+"em", paddingBottom: 0}}>
        <div class="container">
          <div class="row">
            <div class="col-sm-12">
              <h3>Contact Information</h3>
              <div class="row contact-info-wrap">
                <div class="col-md-3">
                  <p><span><i class="icon-location"></i></span> VEGACE CORP<br />
                   2315 Rosemead Blvd. <br/> South El Monte, CA 91733</p>
                </div>
                <div class="col-md-3">
                  <p><span><i class="icon-phone3"></i></span> Toll free: <a href="tel://8006451730">800-645-1730</a><br />
                    Tel: <a href="tel://6265758650">626-575-8650</a><br />
                    Tel: <a href="tel://6265758651">626-575-8651</a><br />
                    Fax: <a href="tel://6265750017">626-575-0017</a><br />
                  </p>
                </div>
                <div class="col-md-3">
                  <p><span><i class="icon-paperplane"></i></span> <a href="mailto:info@vegace.com">info@vegace.com</a></p>
                </div>
                <div class="col-md-3">
                  <p><span><i class="icon-globe"></i></span> <a href="https://www.vegace.com">www.vegace.com</a></p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>
  )
}

export default Contact;
