import React from "react";
import {
  Link
} from "react-router-dom";

function Order() {
  return (
    <div>
      <div class="breadcrumbs">
        <div class="container">
          <div class="row">
            <div class="col">
              <p class="bread"><span><Link to="/">Home</Link></span> / <span>Order</span></p>
            </div>
          </div>
        </div>
      </div>

      <div id="colorlib-order" style={{paddingTop: 1+"em", paddingBottom: 0}}>
        <div class="container">
          <h2>Order Form</h2>
          {/* <p><img src="shoes/vegace_logo.gif" /> <img src="shoes/lavega_logo.gif" /></p> */}
          <p><b>WHOLESALE ONLY</b></p>
          <p>Please use the following form to place your order.</p>
          <p><a href="shoes/Order_Sheet.pdf" target="_blank"><u>Download the PDF format order form.</u></a></p>
          <p>Fax to us at 626-575-0017 or E-mail to <a href="mailto:sales@vegace.com">sales@vegace.com</a></p>
        </div>
      </div>

    </div>
  )
}

export default Order;