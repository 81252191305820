import React from "react";
import {
  Link
} from "react-router-dom";

function About() {
  return (
    <div>
      <div class="breadcrumbs">
        <div class="container">
          <div class="row">
            <div class="col">
              <p class="bread"><span><Link to="/">Home</Link></span> / <span>About</span></p>
            </div>
          </div>
        </div>
      </div>
      <div class="colorlib-about" style={{paddingTop: 1+"em", paddingBottom: 0}}>
        <div class="container">
          <div class="row row-pb-lg">
            <div class="col-sm-6 mb-3">
              <div class="item">
                <div class="product-entry border">
                  <Link className="prod-img" to={`/product/9001`}>
                    <img src={`shoes/products/large/9001.jpg`} class="img-fluid" alt={`9001`} />
                  </Link>
                </div>
              </div>
            </div>
            <div class="col-sm-6">
              <div class="about-wrap">
                <h2>About Us</h2>
                {/* <p><img src="shoes/vegace_logo.gif" alt="VEGACE" /> <img src="shoes/lavega_logo.gif" alt="LAVEGA" /></p> */}
                <p>VEGACE and LAVEGA are registered trademarks of quality footwear for men, women and children. VEGACE CORP designs, manufactures, imports, and distributes VEGACE and LAVEGA shoes to markets throughout the United States, Canada, and Mexico.</p>
                <p>We are committed to developing, updating, and improving our product lines to provide the best quality footwear to our consumers. We offer products at a competitive price and yet maintain the best quality product in the industry.</p>
                <p>We look forward to serving you in your footwear needs.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default About;