import React from "react";
import {
  Link
} from "react-router-dom";
import * as ds from './DataSource';

function CategoriesSidebar() {
  return (
              <div class="col-lg-3 col-xl-3">
                <div class="row">
                  <div class="col-sm-12">
                    <div class="side border mb-1">
                      <h3>Categories</h3>
                      <ul>
                      {ds.findCategories().map(category => (
                        <li key={category.categoryId}><Link to={`/products/${category.categoryId}`}>{category.categoryName}</Link></li>
                      ))}
                      </ul>
                    </div>
                  </div>
                </div>
              </div>    
  )    
}

function Home() {
  return (
    <div>

      <div class="breadcrumbs">
        <div class="container">
          <div class="row">
            <div class="col">
              <p class="bread"><span><Link to="/">Home</Link></span> / <span>Welcome</span></p>
            </div>
          </div>
        </div>
      </div>

      <div class="colorlib-product" style={{paddingTop: 1+"em", paddingBottom: 0}}>
        <div class="container">
          <div class="row">
            <CategoriesSidebar />
            <div class="col-lg-9 col-xl-9">
              <div class="row row-pb-md">
                <div class="col-lg-12 mb-12">
                  <h1>VEGACE CORP</h1>
                  <p>We research, design, engineer and manufacture every shoe and boot we sell to provide you the best in comfort, style, durability and slip-resistance.</p>
                  <p>If you are interested in carrying our products, please contact us directly for more information.</p>
                  <Link className="prod-img" to={`/product/9001`}>
                    <img src={`shoes/products/medium/9001.jpg`} class="img-fluid" alt={`9001`} />
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>
  )
}

export default Home;