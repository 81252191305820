import React from "react";
import {
  Link,
  useParams,
  useLocation
} from "react-router-dom";
import * as ds from './DataSource';

function Product(props) {
  let { productId } = useParams();
  let query = new URLSearchParams(useLocation().search);
  if (!productId) {
    if (query.get("pid")) {
      productId = query.get("pid");
    }
  }
  let product = ds.findProduct(productId);
  if (!product) {
    return (
      <div>
        <div class="breadcrumbs">
          <div class="container">
            <div class="row">
              <div class="col">
                <p class="bread"><span><Link to="/">Home</Link></span> / <span>Product Details</span> / <span>Unknown</span></p>
              </div>
            </div>
          </div>
        </div>

        <div id="colorlib-product" style={{paddingTop: 1+"em", paddingBottom: 0}}>
          <div class="container">
            <h4>Product Not Found</h4>
          </div>
        </div>
      </div>
    )
  } else {
    return (
      <div>
        <div class="breadcrumbs">
          <div class="container">
            <div class="row">
              <div class="col">
                <p class="bread"><span><Link to="/">Home</Link></span> / <span>Product Details</span> / <span>{ product.productName }</span></p>
              </div>
            </div>
          </div>
        </div>

        <div class="colorlib-product" style={{paddingTop: 1+"em", paddingBottom: 0}}>
          <div class="container">
            <div class="row row-pb-lg product-detail-wrap">
              <div class="col-sm-8">
                <div class="item">
                  <div class="product-entry border">
                    {/* <Link className="prod-img" to={`/product?pid=${product.productId}`}> */}
                      <img src={`shoes/products/large/${product.productId}.jpg`} class="img-fluid" alt={`${product.productId}`} />
                    {/* </Link> */}
                  </div>
                </div>
              </div>
              <div class="col-sm-4">
                <div class="product-desc">
                  <h3>{product.productName}</h3>
                  <p>{product.description}</p>
                </div>
              </div>
            </div>
          </div>
        </div>        
      </div>
    )    
  }
}

export default Product;