import categories from './data/categories.json';
import categoriesProducts from './data/categoriesProducts.json';
import products from './data/products.json';

function findCategory(categoryId) {
  let results = categories.filter(category => {
    return category.categoryId === categoryId
  });
  return results.length > 0 ? results[0] : null;
}

function findProduct(productId) {
  let results = products.filter(product => {
    return product.productId === productId
  });
  return results.length > 0 ? results[0] : null;
}

function findCategories() {
  return categories;
}

function findProductsByCategory(categoryId) {
  let results = categoriesProducts.filter(categoryProduct => {
    return categoryProduct.categoryId === categoryId;
  });
  let productResults = [];
  results.forEach(categoryProduct => {
    productResults.push(findProduct(categoryProduct.productId));
  })
  return productResults;
}

export {findCategory, findProduct, findCategories, findProductsByCategory}
