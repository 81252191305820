import React from "react";
import {
  Link,
  useLocation,
  useParams
} from "react-router-dom";
import * as ds from './DataSource';

function CategoriesSidebar() {
  return (
              <div class="col-lg-3 col-xl-3">
                <div class="row">
                  <div class="col-sm-12">
                    <div class="side border mb-1">
                      <h3>Categories</h3>
                      <ul>
                      {ds.findCategories().map(category => (
                        <li key={category.categoryId}><Link to={`/products/${category.categoryId}`}>{category.categoryName}</Link></li>
                      ))}
                      </ul>
                    </div>
                  </div>
                </div>
              </div>    
  )    
}

function ProductList(props) {
  return (
              <div class="col-lg-9 col-xl-9">
                <div class="row row-pb-md">
                {ds.findProductsByCategory(props.categoryId).map(product => (
                  <div class="col-lg-4 mb-4 text-center">
                    <div class="product-entry border">
                      <Link className="prod-img" to={`/product/${product.productId}`}>
                        <img src={`shoes/products/medium/${product.productId}.jpg`} class="img-fluid" alt={`${product.productId}`} />
                      </Link>
                      <div class="desc">
                        <h2><Link to={`/product/${product.productId}`}>{product.productName}</Link></h2>
                        <span>{product.description}</span>
                      </div>
                    </div>
                  </div>
                ))}
                </div>
              </div>    
  )    
}

function Products(props) {
    let { categoryId } = useParams();
    console.log("categoryId=" + categoryId);
    let query = new URLSearchParams(useLocation().search);
    if (!categoryId) {
      if (query.get("cid")) {
        categoryId = query.get("cid");
      }
    }

    // default category
    if (!categoryId) { categoryId = "slip"; }

    let category = ds.findCategory(categoryId);
    let categoryName = category ? category.categoryName : "Unknown";
    return (
      <div>
        <div class="breadcrumbs">
          <div class="container">
            <div class="row">
              <div class="col">
                <p class="bread"><span><Link to="/">Home</Link></span> / <span>Products</span> / <span>{ categoryName }</span></p>
              </div>
            </div>
          </div>
        </div>
        <div class="colorlib-product" style={{paddingTop: 1+"em", paddingBottom: 0}}>
          <div class="container">
            <div class="row">
              <CategoriesSidebar />
              <ProductList categoryId={categoryId} />
            </div>
          </div>
        </div>
      </div>
    )
}

export default Products;